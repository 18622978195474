<template>
  <moe-page title="新增活动">
    <moe-form
      class="default-form"
      ref="nYuanOptionalForm"
      :showBack="false"
      :showClose="true"
      :model="nYuanOptionalParams"
      :rules="rules">
      <div style="width: 50%;" class="margin-0-auto">
        <div class="fwb mb-20 font-20">基本信息</div>

        <el-form-item label="活动名称" prop="name" label-width="150px">
          <el-input class="w-300" v-model.trim="nYuanOptionalParams.name" placeholder="请输入活动名称，2到16个文字组合" clearable maxlength="16"></el-input>
          <div class="color-info font-10 lh1 mt-10">活动名称仅用于商家自己管理活动，不会显示给用户</div>
        </el-form-item>

        <el-form-item label="活动时间" prop="startTime" label-width="150px">
          <el-date-picker
            :disabled="IS_EDIT"
            style="width: 100%;"
            placement="bottom-start"
            v-model="datetime"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            @change="(datetime) => ([nYuanOptionalParams.startTime, nYuanOptionalParams.endTime] = datetime || ['', ''])" />
        </el-form-item>

        <el-form-item label="活动封面" label-width="150px">
          <moe-upload-file
            v-model="nYuanOptionalParams.cover"
            ossKey="SHOP_MEDIA"
            :default-file-list="coverList"
            upload-text="上传活动封面"
            @change="() => $refs['nYuanOptionalForm'].validateField('cover')" />
        </el-form-item>

        <div class="fwb mb-20 font-20">活动内容</div>

        <div class="df aic">
          <el-form-item label="" prop="money" label-width="150px">
            <el-input class="w-300" :value="nYuanOptionalParams.money" placeholder="请输入" maxLength="8" @input="(value) => nYuanOptionalParams.money = $moe_formatter.formatterMoney(value)">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>

          <el-form-item label="" prop="number" label-width="50px">
            <el-input class="w-300" :value="nYuanOptionalParams.number" placeholder="请输入" maxLength="6" @input="(value) => nYuanOptionalParams.number = $moe_formatter.formatterInteger(value)">
              <template slot="prepend">任选</template>
              <template slot="append">件</template>
            </el-input>
          </el-form-item>
        </div>
      </div>
      <div class="fwb mb-20 font-20">商品范围</div>

      <div style="height: 700px;min-height: 700px;" class="df fdc">
        <moe-table
          :stripe="true"
          :key="itemKey"
          :data="nYuanOptionalParams.configList"
          :mode="false"
          :showPage="false"
          :number-show="false"
          :params="{ pageNum: 1 }"
          emptyText="请添加活动商品"
          rowKey="goodsId">
          <!-- 操作按钮 -->
          <el-form-item prop="configList" slot="tool">
            <el-button type="primary" icon="el-icon-circle-plus-outline" @click="showDialog = true;">添加商品</el-button>
          </el-form-item>
          <el-table-column label="商品信息" min-width="200">
            <template slot-scope="{ row }">
              <div class="df aic">
                <moe-image :src="row.coverUrl" width="80px" height="80px" />
                <div class="ml-10 df fdc aifs">
                  <p class="fwb tal">{{ row.name }}</p>
                  <p class="font-12 color-info">商品ID: {{ row.goodsId }}</p>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="销售价" min-width="150">
            <template slot-scope="{ row }">
              {{ `¥ ${row.minPrice}` }}{{ $moe_math.mathGreaterThan(row.maxPrice, row.minPrice) ? ` - ¥ ${row.maxPrice}` : '' }}
            </template>
          </el-table-column>

          <el-table-column label="库存" prop="stock" min-width="80" />

          <el-table-column label="操作" min-width="350" fixed="right">
            <div class="moe-table_btns df aic jcfs" slot-scope="{ row, $index }">
              <template  v-if="$index > 0">
                <el-button type="success" size="mini" icon="el-icon-upload2" @click="handleClick('topping', row, $index)">置顶</el-button>
                <el-button type="primary" size="mini" icon="el-icon-top" @click="handleClick('top', row, $index)">上移</el-button>
              </template>
              <el-button v-if="$index < (nYuanOptionalParams.configList.length - 1)" type="primary" size="mini" icon="el-icon-bottom" @click="handleClick('bottom', row, $index)">下移</el-button>
              <el-button type="danger" size="mini" icon="el-icon-delete" @click="handleClick('delete', row, $index)">移除</el-button>
            </div>
          </el-table-column>
        </moe-table>
      </div>

      <template slot="tool">
        <el-button type="primary" icon="el-icon-upload2" :loading="paramsLoad" @click="submit()">提交</el-button>
      </template>
    </moe-form>

    <goodsDialog
      :showActivityRepel="true"
      :showDialog.sync="showDialog"
      :defaultSelectIds="nYuanOptionalParams.configList && nYuanOptionalParams.configList.length ? nYuanOptionalParams.configList.map(({ goodsId }) => goodsId) : []"
      @close="handleClose">
    </goodsDialog>
  </moe-page>
</template>

<script>
import goodsDialog from '@/views/ActivityManage/components/goodsDialog.vue';
export default {
  name: 'ActivityManageNYuanOptionalAdd',
  components: {
    goodsDialog
  },
  computed: {
    IS_EDIT() {
      return this.nYuanOptionalParams.id ? true : false;
    },
  },
  data() {
    const checkMoney = (rule, value, callback) => {
      if (!value) {
        callback(new Error(`请输入金额`));
      } else {
        if (this.$moe_math.mathLessThanOrEqualTo(value, 0.1)) {
          callback(new Error(`金额必须大于0.1元`));
        } else {
          callback();
        }
      }
    }
    const checkNumber = (rule, value, callback) => {
      if (!value) {
        callback(new Error(`请输入件数`));
      } else {
        if (this.$moe_math.mathLessThanOrEqualTo(value, 1)) {
          callback(new Error(`件数必须大于1件`));
        } else {
          callback();
        }
      }
    }
    const verify = this.$moe_verify.verifyForm
    const rules = {
      name: verify.isExtent(['请输入活动名称', '长度为 2 ~ 16位'], '2~16'),
      startTime: verify.isEmpty('请选择活动时间'),
      money: [{ required: true, validator: checkMoney, trigger: ['blur', 'change'] }],
      number: [{ required: true, validator: checkNumber, trigger: ['blur', 'change'] }],
      configList: verify.isEmpty('请添加活动商品'),
    };
    return {
      nYuanOptionalParams: {
        id: '',
        name: '',
        startTime: '',
        endTime: '',
        cover: '',
        money: '',
        number: '',
        configList: [],
      },
      datetime: [],
      coverList: [],
      rules,
      itemKey: Math.random(),
      showDialog: false,
      paramsLoad: false,
    }
  },
  methods: {
    handleClose(selectList) {
      selectList.forEach((item) => {
        let findItem = this.nYuanOptionalParams.configList.find(({ goodsId }) => item.id === goodsId)

        if (!findItem) {
          this.nYuanOptionalParams.configList.push({
            ...item,
            goodsId: item.id,
            id: '',
          });
        }
      })

      this.showDialog = false;
    },
    handleClick(type, { name }, index) {
      let { configList } = this.nYuanOptionalParams
      switch (type) {
        case 'delete':
          this.$moe_layer.confirm(`您确定要移除当前选项"${name}"吗？`, () => {
            configList.splice(index, 1);
          });
          break;
        case 'topping':
          this.$moe_layer.confirm(`您确定要置顶当前选项"${name}"吗？`, () => {
            const row = this.$moe_lodash.cloneDeepData(configList[index]);
            configList.splice(index, 1);
            configList.unshift(row);
            this.$moe_msg.success('置顶成功,提交后更新');
          });
          break;
        case 'top':
          this.$moe_layer.confirm(`您确定要上移当前选项"${name}"吗？`, () => {
            configList.splice(index - 1, 2, configList[index], configList[index - 1]);
            this.$moe_msg.success('上移成功,提交后更新');
          });
          break;
        case 'bottom':
          this.$moe_layer.confirm(`您确定要下移当前选项"${name}"吗？`, () => {
            configList.splice(index, 2, configList[index + 1], configList[index]);
            this.$moe_msg.success('下移成功,提交后更新');
          });
          break;
      }
    },
    submit() {
      this.$refs['nYuanOptionalForm'].validate(() => {
        if (!this.nYuanOptionalParams.configList.length) {
          this.$moe_msg.warning('请完善资料 !');
        }
        this.paramsLoad = true;
        if (this.IS_EDIT) {
          this.req_updateOptional();
        } else {
          this.req_addOptional();
        }
      })
    },
    /** 创建N元任选 */
    req_addOptional() {
      this.$moe_api.NYUANOPTIONAL_API.addOptional({
        ...this.nYuanOptionalParams,
        configList: this.nYuanOptionalParams.configList.map(({ goodsId }) => {
          return {
            id: '',
            goodsId
          }
        })
      }).then((data) => {
        if (data.code === 200) {
          this.$moe_msg.success('新增活动成功', {
            completionHandler: () => {
              this.$moe_coordinator.navigateBack();
            }
          });
        } else {
          this.$moe_msg.error(data.message);
        }
      }).finally(() => {
        this.paramsLoad = false;
      })
    },
    /** 修改N元任选 */
    req_updateOptional() {
      this.$moe_api.NYUANOPTIONAL_API.updateOptional({
        ...this.nYuanOptionalParams,
        configList: this.nYuanOptionalParams.configList.map(({ goodsId, id }) => {
          return {
            id: id || '',
            goodsId
          }
        })
      }).then((data) => {
        if (data.code === 200) {
          this.$moe_msg.success('编辑活动成功', {
            completionHandler: () => {
              this.$moe_coordinator.navigateBack();
            }
          });
        } else {
          this.$moe_msg.error(data.message);
        }
      }).finally(() => {
        this.paramsLoad = false;
      })
    },
    /** 根据商品ID获取商品列表 */
    getQueryShopGoodsByIds(goodsList) {
      this.$moe_api.GOODS_API.getQueryShopGoodsByIds({ ids: goodsList.map(({ goodsId }) => goodsId).join(',') }).then((data) => {
        if (data.code === 200) {
          this.nYuanOptionalParams.configList = goodsList.map(({ goodsId, id }) => {
            let findGoodsItem = data.result.find(({ id }) => goodsId === id);
            return {
              ...findGoodsItem,
              goodsId: findGoodsItem.id,
              id,
            }
          })
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    /** 获取N元任选详情 */
    req_getOptionalDetail() {
      if (this.$route.query.id) {
        this.$moe_api.NYUANOPTIONAL_API.getOptionalDetail({ id: this.$route.query.id }).then((data) => {
          if (data.code === 200) {
            let { id, name, startTime, endTime, cover, money, number, configList } = data.result;

            this.nYuanOptionalParams = {
              id,
              name,
              startTime,
              endTime,
              cover: this.$moe_yunHelper.formatterImageUrl(cover),
              money,
              number,
              configList: [],
            }

            this.datetime = [startTime, endTime];

            if (cover) {
              this.coverList = [{
                url: cover,
                tempFilePath: this.$moe_yunHelper.getTempFilePath(cover)
              }]
            }

            if (configList.length) {
              this.getQueryShopGoodsByIds(configList);
            }

          } else {
            this.$moe_msg.error(data.message);
          }
        })
      }
    }
  },
  mounted() {
    this.req_getOptionalDetail();
  },
}
</script>

<style lang="scss">

</style>